import React, { RefObject, useContext } from 'react';
import Map from '../components/Map';
import { cleanRoutes, getPoints } from '../utils/utils';
import { Location } from '../../../types';
import { getQueryParams } from '../../common/utils/location';
import { FlightMasterContext } from '../context/flightmaster-context';
import { useLocation } from '../../common/hooks';
import { useGetPointsQuery, useGetRoutesQuery } from '../../../types/generated';

interface Props {
  routeIndex?: number;
  className?: string;
  onClosePopup?: (location: Location) => void;
  innerRef: RefObject<any>;
}

export default function MapContainer(props: Props) {
  const { routeIndex = 0 } = props;
  const { showAllPoints } = useContext(FlightMasterContext);
  const { location: { search } } = useLocation();
  const state = getQueryParams(search);
  const { faction } = state;

  const res = useGetRoutesQuery({ variables: state });
  const res2 = useGetPointsQuery();
  const { data: { getRoutes: routes = [] } = {} } = res;
  const { data: { getPoints: points = [] } = {} } = res2;

  const pointsX = showAllPoints ? points : getPoints(points, faction, true);

  const routesX = cleanRoutes(routes);
  const index = routeIndex < routesX.length ? routeIndex : 0;
  const paths = (routesX.length && routesX[index]) ? routesX[index].paths : [];

  return (
    <Map
      {...props}
      points={pointsX}
      paths={paths}
    />
  );
}
